@import '../../style/themes/index';
@import '../../style/mixins/index';

@message-prefix-cls: ~'@{ant-prefix}-message';

.@{message-prefix-cls} {
  .reset-component();

  position: fixed;
  top: 8px;
  left: 0;
  z-index: @zindex-message;
  width: 100%;
  pointer-events: none;

  &-notice {
    padding: 8px;
    text-align: center;
  }

  &-notice-content {
    display: inline-block;
    padding: @message-notice-content-padding;
    background: @message-notice-content-bg;
    border-radius: @border-radius-base;
    box-shadow: @shadow-2;
    pointer-events: all;
  }

  &-success .@{iconfont-css-prefix} {
    color: @success-color;
  }

  &-error .@{iconfont-css-prefix} {
    color: @error-color;
  }

  &-warning .@{iconfont-css-prefix} {
    color: @warning-color;
  }

  &-info .@{iconfont-css-prefix},
  &-loading .@{iconfont-css-prefix} {
    color: @info-color;
  }

  .@{iconfont-css-prefix} {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: @font-size-lg;
  }

  &-notice.move-up-leave.move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@import './rtl';

@ant-prefix: ant;@global-scope: .global-scope;@primary-color: #507de3;@error-color: #F9467E;@text-color: #475061;@heading-color: #3d3d3d;@text-disabled: #9a9da1;@layout-header-background: #F4F4F4;@layout-body-background: #F4F4F4;@layout-header-height: 74px;@tablet-size: 1050px;@mobile-size: 768px;@font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;