@import url('../../swiper-vars.less');

:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled & {
    display: none !important;
  }
  &:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    content: 'prev';
  }
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    content: 'next';
  }
  right: 10px;
  left: auto;
}
.swiper-button-lock {
  display: none;
}

@ant-prefix: ant;@global-scope: .global-scope;@primary-color: #507de3;@error-color: #F9467E;@text-color: #475061;@heading-color: #3d3d3d;@text-disabled: #9a9da1;@layout-header-background: #F4F4F4;@layout-body-background: #F4F4F4;@layout-header-height: 74px;@tablet-size: 1050px;@mobile-size: 768px;@font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;